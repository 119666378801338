const ShippingAddress = ({ address, setSelectedShippingAddress }) => {
  const {
    shipAddress1,
    shipAddress2,
    shipCity,
    shipCompany,
    shipCountryName,
    shipEmail,
    shipFirstName,
    shipLastName,
    shipPhone,
    shipState,
    shipSuite,
    shipZipcode,
    addressTitle,
  } = address;

  // Function to filter out empty values and join with a comma
  const formatAddress = (addressParts) => {
    return addressParts.filter((part) => part).join(", ");
  };

  return (
    <div>
      <div className="relative border border-gray-200 rounded-md overflow-hidden">
        <div className="flex flex-wrap items-center justify-between overflow-hidden bg-gray-100 p-2.5">
          <div>
            <span>{addressTitle || ""}</span>
          </div>
          <div className="text-gray-800 h-6 z-5">
            <input
              type="radio"
              className="accent-emerald-500/500 w-6 h-6"
              name="shippingAddress"
              value={address.id}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedShippingAddress(address);
                } else {
                  setSelectedShippingAddress("");
                }
              }}
            />
          </div>
        </div>

        {addressTitle !== "Other" ? (
          <div className="text-left p-2 flex justify-center flex-col items-start min-h-48">
            <div className="grid grid-cols-12">
              <div className="col-span-1">
                <span className="material-icons text-[20px] pt-1 pl-1">
                  person_pin
                </span>
              </div>
              <div className="col-span-11">
                <span>{`${shipFirstName} ${shipLastName}`}</span>
              </div>
              <div className="col-span-1">
                <span className="material-icons text-[20px] pt-1 pl-1">
                  store
                </span>
              </div>
              <div className="col-span-11">{shipCompany}</div>
              <div className="col-span-1">
                <span className="material-icons text-[20px] pt-1 pl-1">
                  place
                </span>
              </div>
              <div className="col-span-11 ">
                <p className="">
                  {`${formatAddress([shipAddress1, shipAddress2, shipSuite])}`},
                </p>
                <p className="">{`${formatAddress([
                  shipCity,
                  shipState,
                  shipZipcode,
                ])}`}</p>
                <p>{shipCountryName}</p>
              </div>
              {shipEmail && (
                <>
                  <div className="col-span-1">
                    <span className="material-icons text-[20px] pt-1 pl-1">
                      email
                    </span>
                  </div>
                  <div className="col-span-11">{shipEmail}</div>
                </>
              )}
              {shipPhone && (
                <>
                  <div className="col-span-1">
                    <span className="material-icons text-[20px] pt-1 pl-1">
                      call
                    </span>
                  </div>
                  <div className="col-span-11">{shipPhone}</div>
                </>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ShippingAddress;
